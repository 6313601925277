import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;

	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 829.000000 871.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,871.000000) scale(0.100000,-0.100000)">
					<path d="M5993 6423 c43 -2 110 -2 150 0 40 1 5 3 -78 3 -82 0 -115 -2 -72 -3z"/>
					<path d="M3800 8010 c-30 -4 -111 -13 -180 -20 -124 -11 -258 -33 -315 -50
-26 -8 -24 -9 15 -5 146 15 210 25 231 36 33 17 791 17 834 -1 17 -7 59 -16
95 -21 121 -15 185 -27 213 -38 16 -6 48 -11 72 -11 24 0 47 -4 50 -10 3 -5
14 -10 23 -10 9 0 39 -9 67 -19 27 -11 84 -30 125 -41 41 -12 83 -26 92 -31
10 -5 31 -9 48 -9 16 0 30 -4 30 -9 0 -5 10 -13 23 -17 12 -4 45 -17 74 -30
28 -13 60 -24 70 -24 11 0 23 -4 29 -10 12 -12 151 -80 164 -80 5 0 44 -22 87
-49 43 -26 112 -67 153 -91 41 -24 86 -50 100 -59 14 -9 35 -21 47 -27 48 -26
264 -194 353 -274 202 -181 438 -429 514 -538 20 -29 55 -78 77 -110 72 -101
128 -189 162 -254 18 -34 46 -83 62 -108 38 -59 72 -130 138 -280 65 -150 74
-171 86 -190 5 -8 17 -42 27 -75 9 -33 20 -64 23 -70 4 -5 13 -35 20 -65 7
-30 20 -77 28 -105 30 -99 33 -112 33 -134 0 -12 5 -31 10 -41 10 -18 15 -46
40 -222 4 -31 11 -62 15 -69 10 -14 23 -184 34 -414 l7 -160 2 197 c2 218 -12
377 -53 608 -4 19 -12 62 -20 95 -7 33 -19 85 -25 115 -26 121 -101 347 -167
505 -37 89 -181 377 -198 395 -8 9 -15 19 -15 23 0 14 -99 173 -119 191 -12
11 -21 23 -21 28 0 15 -64 110 -113 168 -26 30 -72 87 -103 125 -111 140 -313
337 -481 471 -54 43 -100 81 -103 84 -3 3 -12 10 -21 15 -24 15 -92 64 -164
118 -35 26 -67 47 -70 47 -3 0 -26 15 -52 34 -54 38 -236 136 -252 136 -6 0
-28 10 -49 23 -20 13 -57 31 -82 41 -25 10 -65 28 -90 39 -108 48 -449 167
-479 167 -9 0 -52 10 -96 21 -44 12 -102 25 -130 30 -27 4 -88 15 -135 24
-162 31 -623 53 -740 35z"/>
<path d="M3185 7915 c-5 -2 -66 -19 -135 -38 -142 -40 -185 -55 -177 -63 4 -4
115 25 202 55 17 5 50 15 75 22 25 6 56 15 70 19 l25 8 -25 0 c-14 0 -29 -1
-35 -3z"/>
<path d="M3970 7810 c-47 -11 -116 -86 -126 -135 -9 -52 12 -113 55 -156 45
-45 85 -57 179 -51 l72 4 0 32 c0 17 -3 41 -6 54 -6 22 -10 23 -73 16 -58 -5
-69 -3 -93 16 -54 42 -24 120 45 120 17 0 49 3 70 6 37 7 37 7 37 55 l0 49
-62 -1 c-35 -1 -79 -5 -98 -9z"/>
<path d="M2790 7790 c-12 -8 -11 -10 7 -10 12 0 25 5 28 10 8 13 -15 13 -35 0z"/>
<path d="M4318 7790 c-70 -21 -127 -95 -128 -166 0 -47 25 -97 65 -132 42 -38
70 -46 134 -40 59 6 105 37 131 88 71 139 -55 295 -202 250z m102 -127 c50
-64 -29 -143 -93 -93 -51 40 -23 120 42 120 20 0 36 -9 51 -27z"/>
<path d="M2715 7760 c-34 -17 -36 -19 -11 -14 16 3 39 12 50 20 29 20 8 17
-39 -6z"/>
<path d="M3280 7728 c-63 -17 -116 -32 -118 -33 -2 -1 2 -23 9 -48 8 -34 16
-47 32 -49 17 -2 25 -21 49 -112 15 -60 30 -112 33 -114 6 -6 78 9 164 35 76
22 121 67 121 120 0 22 -7 48 -15 59 -8 10 -15 33 -15 51 0 49 -16 81 -52 103
-42 26 -68 24 -208 -12z m160 -86 c-1 -18 -18 -31 -59 -42 -49 -13 -48 -13
-53 20 -3 21 3 26 37 36 38 11 75 4 75 -14z m23 -105 c9 -23 -8 -38 -53 -48
-48 -11 -45 -12 -54 19 -6 17 -2 24 16 31 35 14 85 13 91 -2z"/>
<path d="M4576 7719 c-6 -35 -4 -46 10 -56 15 -11 15 -20 -2 -127 -9 -64 -15
-118 -13 -121 3 -3 26 -8 51 -11 l46 -6 7 46 c4 25 11 48 15 51 5 3 20 -20 35
-52 l26 -57 49 49 49 48 -2 -58 -2 -59 44 -8 c25 -4 46 -7 47 -5 6 8 57 337
53 342 -4 3 -28 8 -55 11 -48 6 -50 5 -94 -40 -24 -25 -47 -46 -51 -46 -4 0
-17 25 -29 55 -12 30 -27 59 -34 64 -6 6 -41 13 -77 17 l-66 6 -7 -43z"/>
<path d="M2545 7686 c-27 -14 -45 -25 -39 -25 12 -1 94 37 94 44 0 7 0 7 -55
-19z"/>
<path d="M3053 7679 l-43 -20 26 -67 c47 -120 50 -138 29 -162 -22 -25 -57
-26 -78 -2 -8 9 -30 55 -47 102 -18 47 -34 86 -35 88 -1 1 -31 -9 -66 -22 -69
-26 -70 -29 -44 -93 10 -24 18 -30 33 -26 16 4 23 -5 38 -47 47 -131 184 -168
276 -75 32 32 52 95 43 134 -10 45 -76 211 -83 210 -4 0 -26 -9 -49 -20z"/>
<path d="M5031 7628 c-10 -32 -10 -46 -1 -63 10 -19 7 -39 -19 -123 -17 -55
-31 -105 -31 -110 0 -11 53 -32 82 -32 13 0 21 12 30 45 7 25 16 45 20 45 4 0
15 -25 23 -55 9 -30 21 -55 27 -55 5 0 29 16 52 35 24 19 46 35 49 35 3 0 -1
-21 -9 -47 -8 -25 -14 -50 -14 -54 0 -7 91 -42 96 -36 4 4 104 331 101 332 -1
1 -23 9 -49 18 l-48 16 -50 -40 c-27 -21 -54 -39 -59 -39 -6 0 -19 28 -29 63
l-20 62 -63 22 c-35 12 -67 23 -70 23 -3 0 -11 -19 -18 -42z"/>
<path d="M2402 7615 c-50 -27 -66 -40 -37 -29 20 8 87 43 94 50 12 12 -19 0
-57 -21z"/>
<path d="M2625 7515 c-45 -25 -88 -56 -97 -72 -27 -42 -23 -87 10 -124 24 -27
37 -33 85 -37 64 -5 71 -14 27 -32 -24 -10 -37 -10 -56 -1 -34 15 -34 15 -34
-19 0 -19 -7 -33 -20 -40 -19 -10 -19 -11 2 -55 18 -37 25 -44 44 -39 18 5 25
-1 39 -29 21 -45 49 -70 72 -65 15 3 18 13 18 54 0 28 6 58 14 67 10 13 11 20
2 31 -10 12 -7 17 16 29 60 32 83 85 59 141 -17 42 -60 66 -116 66 -22 0 -40
4 -40 10 0 5 27 23 60 40 33 17 60 33 60 36 0 16 -42 84 -53 84 -6 0 -48 -20
-92 -45z"/>
<path d="M5457 7449 c-13 -32 -14 -45 -4 -60 9 -15 7 -27 -11 -64 -12 -25 -22
-64 -22 -86 0 -133 160 -207 261 -122 29 25 119 190 119 218 0 11 -61 45 -81
45 -5 0 -29 -39 -52 -87 -45 -92 -75 -120 -109 -102 -43 23 -42 45 6 147 l46
97 -53 27 c-71 37 -81 35 -100 -13z"/>
<path d="M1885 7285 c-47 -36 -105 -82 -128 -103 -120 -107 -157 -143 -221
-207 -271 -273 -478 -561 -641 -890 -83 -168 -98 -206 -156 -380 -16 -49 -36
-110 -44 -135 -8 -25 -22 -74 -30 -110 -7 -36 -21 -96 -30 -135 -9 -38 -18
-86 -21 -105 -54 -392 -56 -418 -51 -662 3 -130 12 -284 21 -348 9 -63 19
-133 21 -155 21 -159 174 -713 214 -770 4 -5 13 -28 20 -50 14 -39 41 -101
107 -242 19 -40 34 -77 34 -82 0 -5 3 -11 8 -13 6 -3 43 -63 117 -193 84 -147
248 -376 370 -515 90 -103 381 -384 455 -439 19 -14 60 -46 91 -71 31 -25 78
-58 104 -75 27 -16 60 -38 74 -47 14 -9 36 -23 48 -30 11 -7 36 -25 55 -40 38
-30 185 -110 278 -152 36 -15 72 -32 80 -37 65 -39 241 -109 275 -109 28 0 15
16 -27 34 -105 43 -150 69 -135 77 9 6 31 0 60 -15 25 -14 71 -32 103 -41 33
-10 67 -21 76 -26 10 -5 26 -9 35 -9 21 0 103 -28 118 -40 10 -7 41 -15 115
-27 19 -3 43 -13 52 -23 16 -17 16 -18 -5 -24 -12 -3 -32 -6 -45 -6 -13 0 -20
-4 -17 -10 3 -5 21 -10 40 -10 19 0 37 -4 40 -10 3 -5 21 -10 38 -10 18 0 45
-7 61 -15 15 -8 44 -15 63 -15 19 0 42 -4 52 -10 39 -20 418 -54 596 -53 328
2 757 51 862 99 17 8 42 14 55 14 13 0 73 16 133 35 61 19 115 35 120 35 36 0
354 143 510 228 200 110 270 154 360 226 17 14 57 45 90 69 122 90 363 329
492 487 77 95 216 291 254 360 14 25 36 63 49 85 39 66 12 32 -46 -57 -29 -46
-57 -87 -61 -93 -4 -5 -26 -37 -48 -69 -22 -33 -47 -67 -55 -76 -8 -8 -37 -44
-65 -79 -27 -35 -67 -82 -87 -105 -20 -23 -49 -54 -64 -71 -68 -76 -146 -156
-215 -217 -126 -113 -144 -128 -155 -128 -6 0 -18 -7 -25 -16 -25 -30 -297
-204 -319 -204 -5 0 -36 -16 -70 -35 -34 -19 -80 -41 -103 -49 -23 -8 -60 -22
-82 -31 -61 -26 -218 -65 -330 -82 -137 -21 -397 -4 -507 32 -40 14 -80 25
-88 25 -14 0 -116 47 -251 116 -124 63 -241 143 -337 231 -52 48 -134 124
-181 167 -47 44 -95 87 -106 97 -11 9 -43 39 -72 65 -28 27 -57 54 -65 60 -7
6 -29 29 -49 50 -19 21 -55 55 -79 74 -24 19 -48 40 -54 46 -11 10 -67 43
-155 90 -24 13 -50 24 -57 24 -8 0 -14 5 -14 10 0 6 -11 10 -24 10 -14 0 -28
5 -31 10 -3 5 -21 9 -38 9 l-32 -2 35 -7 c19 -4 31 -8 25 -9 -17 -2 19 -21 42
-21 12 0 23 -4 25 -9 5 -13 86 -61 103 -61 9 0 18 -6 21 -12 2 -7 35 -38 73
-68 38 -30 74 -64 81 -75 10 -16 10 -18 -1 -8 -7 7 -19 13 -26 13 -12 0 -90
46 -103 60 -3 4 -23 17 -45 30 -22 13 -42 27 -45 30 -10 13 -45 27 -57 23 -17
-6 6 -43 27 -44 8 0 29 -9 45 -19 17 -11 46 -27 65 -37 32 -16 70 -47 142
-115 15 -15 28 -30 28 -33 0 -14 -31 -2 -58 23 -16 15 -40 33 -53 40 -13 6
-42 23 -64 36 -59 37 -194 102 -240 116 -22 7 -49 17 -60 21 -11 5 -29 10 -40
12 -11 2 -30 8 -42 15 -12 7 -33 12 -47 12 -15 0 -34 4 -44 9 -47 25 -310 53
-297 32 3 -6 38 -11 80 -11 41 0 77 -4 80 -10 3 -6 21 -10 38 -10 18 0 45 -7
61 -15 15 -8 43 -15 62 -15 18 0 36 -5 39 -10 3 -5 15 -10 27 -10 22 0 57 -14
173 -70 44 -21 106 -50 137 -64 31 -15 60 -31 63 -36 3 -6 15 -10 25 -10 10 0
25 -8 32 -18 7 -9 33 -33 58 -52 65 -50 102 -90 83 -90 -9 0 -23 8 -32 18 -24
26 -88 72 -100 72 -6 0 -11 4 -11 9 0 5 -12 12 -27 16 -15 3 -39 15 -54 26
-15 10 -34 19 -42 19 -9 0 -18 4 -22 9 -14 24 -181 91 -227 91 -15 0 -30 5
-33 10 -3 6 -19 10 -35 10 -15 0 -30 4 -32 10 -8 25 -378 83 -378 58 0 -4 24
-8 54 -8 35 0 58 -5 66 -15 9 -10 32 -15 76 -15 34 0 65 -4 68 -8 3 -5 40 -17
83 -27 43 -11 80 -22 83 -25 10 -11 77 -33 113 -37 20 -3 37 -9 37 -14 0 -5 8
-9 19 -9 10 0 24 -7 31 -15 7 -8 18 -15 26 -15 17 0 108 -50 112 -62 2 -4 11
-8 20 -8 10 0 27 -11 40 -25 13 -13 48 -36 78 -51 47 -23 83 -58 72 -69 -6 -6
-98 56 -98 66 0 5 -6 9 -14 9 -12 0 -54 21 -139 69 -21 11 -44 21 -51 21 -7 0
-19 7 -26 15 -7 8 -23 15 -36 15 -12 0 -26 5 -29 10 -3 6 -20 10 -36 10 -17 0
-37 7 -45 15 -9 8 -26 15 -39 15 -13 0 -27 4 -30 10 -3 6 -23 10 -44 10 -21 0
-41 3 -45 7 -22 23 -155 50 -283 59 -40 3 -73 10 -73 15 0 5 -31 9 -68 9 -44
0 -75 5 -88 15 -27 21 -38 18 -17 -4 12 -13 37 -21 78 -24 105 -9 209 -29 230
-43 11 -8 38 -14 62 -14 23 0 44 -4 47 -9 3 -5 23 -11 43 -14 75 -11 108 -20
128 -33 11 -8 32 -14 47 -14 15 0 30 -4 33 -10 3 -5 17 -10 29 -10 13 0 29 -7
36 -15 7 -8 21 -15 31 -15 10 0 21 -4 24 -10 3 -5 13 -10 21 -10 8 0 31 -11
52 -23 20 -13 53 -29 72 -35 19 -7 46 -22 60 -35 14 -13 34 -28 45 -35 20 -11
20 -11 -2 -12 -12 0 -25 5 -28 10 -3 6 -14 10 -24 10 -10 0 -35 11 -55 25 -20
14 -45 25 -54 25 -10 0 -35 9 -57 20 -22 11 -51 20 -65 20 -24 0 -24 -1 8 -20
18 -11 39 -20 48 -20 8 0 30 -11 48 -25 18 -14 40 -25 48 -25 9 0 21 -7 28
-15 7 -8 16 -15 21 -15 16 0 90 -58 81 -64 -5 -3 -26 3 -48 14 -21 10 -57 26
-79 35 -22 9 -47 20 -55 25 -9 4 -31 11 -50 15 -18 4 -49 16 -67 26 -19 11
-42 19 -51 19 -10 0 -27 7 -38 15 -10 8 -31 15 -46 15 -15 0 -30 5 -33 10 -3
6 -21 10 -38 10 -18 1 -43 7 -57 15 -14 8 -39 14 -57 15 -17 0 -34 4 -37 9 -3
4 -27 11 -53 14 -89 10 -178 28 -187 38 -13 12 -106 12 -106 -1 0 -5 18 -10
41 -10 22 0 47 -6 55 -14 9 -10 26 -13 49 -9 23 4 43 0 59 -11 14 -9 38 -16
54 -16 15 0 33 -5 39 -11 6 -6 31 -16 55 -21 98 -21 114 -25 135 -36 12 -7 37
-12 56 -12 18 0 37 -3 41 -7 14 -14 83 -35 114 -34 17 1 32 -3 32 -9 0 -6 30
-25 68 -43 37 -17 69 -35 70 -39 2 -5 10 -8 18 -8 19 0 61 -28 67 -44 7 -17
-38 -9 -54 10 -6 8 -20 14 -30 14 -11 0 -27 5 -38 11 -13 9 -23 9 -37 1 -16
-9 -16 -11 -3 -12 9 0 22 -7 29 -15 7 -8 21 -15 30 -15 10 0 20 -3 22 -7 2 -5
34 -24 71 -43 76 -39 113 -70 83 -70 -9 0 -49 15 -89 34 -40 19 -92 39 -117
46 -25 6 -58 18 -73 26 -16 8 -37 14 -48 14 -10 0 -19 5 -19 10 0 6 -8 10 -19
10 -10 0 -34 6 -52 13 -19 8 -45 11 -59 7 -25 -6 -25 -6 5 -12 17 -3 46 -11
65 -18 19 -7 44 -16 55 -20 19 -7 55 -22 155 -68 25 -11 62 -27 82 -36 20 -9
40 -21 43 -26 3 -6 14 -10 24 -10 10 0 26 -9 36 -20 18 -20 17 -20 -8 -20 -15
0 -41 8 -59 18 -18 10 -58 27 -88 37 -30 11 -59 23 -64 27 -13 11 -66 10 -66
0 0 -5 19 -17 43 -26 23 -10 66 -29 95 -42 29 -13 59 -24 67 -24 8 0 15 -4 15
-10 0 -5 7 -10 15 -10 9 0 18 -7 21 -15 4 -9 19 -15 36 -15 17 0 27 -4 23 -10
-3 -5 3 -10 14 -10 23 0 121 -51 121 -62 0 -5 -11 -8 -25 -8 -16 0 -25 6 -25
15 0 8 -6 15 -13 15 -18 0 -75 28 -82 41 -4 5 -17 9 -31 9 -13 0 -24 5 -24 10
0 6 -11 10 -24 10 -14 0 -28 5 -31 10 -3 5 -19 10 -35 10 -16 0 -31 4 -34 8
-3 5 -33 17 -67 28 -34 10 -63 24 -66 30 -2 7 -13 9 -24 7 -18 -5 -71 12 -121
38 -10 5 -28 9 -42 9 -13 0 -29 6 -35 14 -6 8 -24 16 -39 19 -61 12 -118 29
-126 38 -6 5 -22 9 -37 9 -15 0 -31 5 -34 10 -3 6 -19 10 -35 10 -16 0 -32 5
-35 10 -3 6 -16 10 -29 10 -12 0 -31 7 -42 15 -10 8 -32 15 -49 15 -16 0 -38
7 -48 15 -11 7 -25 11 -32 9 -7 -3 -36 6 -66 20 -29 14 -60 26 -68 26 -9 0
-25 7 -35 15 -11 8 -29 15 -40 15 -12 0 -32 9 -46 20 -23 17 -24 19 -6 26 10
4 33 2 50 -4 18 -7 39 -12 48 -12 9 0 18 -7 22 -15 3 -10 19 -15 47 -15 43 0
47 11 6 17 -12 2 -26 8 -31 13 -6 6 -24 10 -42 10 -18 0 -34 5 -36 10 -2 6
-17 13 -33 16 -16 3 -34 12 -38 20 -4 8 -21 14 -37 14 -17 0 -30 4 -30 9 0 5
-10 12 -22 16 -21 7 -19 8 11 12 19 3 37 0 40 -5 6 -9 23 -14 106 -32 62 -13
119 -31 133 -42 16 -14 24 -6 16 16 -4 11 -18 16 -49 16 -29 0 -45 5 -49 15
-3 8 -12 15 -21 15 -9 0 -27 7 -40 16 -13 9 -29 13 -35 9 -6 -4 -22 0 -35 9
-13 9 -31 16 -40 16 -8 0 -15 5 -15 10 0 6 13 10 29 10 17 0 33 -4 36 -10 3
-5 26 -10 50 -10 24 0 47 -4 50 -10 3 -5 17 -10 31 -10 27 0 117 -25 146 -41
10 -5 26 -9 35 -9 15 0 13 4 -7 20 -16 12 -41 20 -67 20 -24 0 -43 5 -43 10 0
6 -15 10 -34 10 -18 0 -39 7 -46 15 -7 8 -27 15 -45 15 -18 0 -38 7 -45 15 -7
8 -25 15 -41 15 -15 0 -31 5 -34 10 -3 6 -15 10 -27 10 -11 0 -31 9 -45 20
-14 11 -32 20 -39 20 -20 0 -17 14 6 35 18 17 25 17 55 6 19 -7 53 -13 75 -13
22 0 43 -4 45 -9 7 -11 125 -9 125 2 0 5 -6 9 -12 10 -60 2 -95 11 -112 29
l-20 20 46 0 c26 0 50 -5 53 -10 8 -12 75 -13 75 -1 0 14 -50 29 -114 35 -32
2 -64 10 -69 15 -6 6 -24 11 -39 11 -35 0 -107 17 -182 43 -55 19 -57 21 -35
30 14 6 113 10 234 8 144 -1 205 2 195 9 -8 5 -44 10 -80 10 -45 0 -73 5 -89
17 -23 16 -23 17 -5 23 10 4 54 5 97 2 67 -4 77 -3 77 12 0 9 -7 19 -16 23
-26 10 5 23 55 23 74 0 42 7 -66 14 -95 6 -104 5 -120 -14 -32 -37 -91 -23
-97 22 -2 16 -22 22 -113 29 -162 13 -299 46 -388 92 -123 64 -304 239 -435
419 -33 46 -68 94 -77 107 -10 14 -29 44 -43 67 -14 23 -39 62 -57 86 -37 53
-87 138 -110 188 -8 19 -33 73 -55 119 -58 124 -178 459 -178 501 0 8 -11 60
-25 115 -14 55 -25 119 -25 143 0 24 -4 51 -9 61 -5 9 -11 46 -13 82 l-3 64
48 -2 c41 -1 47 1 47 19 0 19 5 19 88 13 48 -3 104 -8 125 -11 31 -5 37 -3 37
13 0 10 3 35 6 56 l7 37 -41 0 c-69 0 -72 3 -72 87 0 72 -1 76 -34 109 -29 29
-41 34 -79 34 -71 0 -111 -40 -128 -129 -5 -30 -7 9 -3 112 3 87 9 169 14 183
6 14 10 51 10 81 0 31 4 70 9 87 5 17 17 67 26 111 21 99 59 230 91 313 13 34
24 68 24 76 0 8 9 31 19 53 11 21 31 70 45 108 15 39 34 84 43 100 9 17 34 66
55 110 22 44 48 91 58 105 9 14 30 50 47 80 16 30 50 87 76 127 26 39 47 74
47 78 0 3 12 18 26 33 14 15 39 47 57 72 46 67 242 283 355 392 95 91 183 167
293 251 44 34 57 47 45 47 -3 0 -43 -29 -91 -65z m-1111 -2431 c12 -4 16 -20
16 -53 0 -56 -3 -60 -35 -53 -22 4 -25 10 -25 46 0 23 5 47 12 54 14 14 12 14
32 6z m1314 -2579 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z
m-18 -40 c7 -9 8 -15 2 -15 -5 0 -12 7 -16 15 -3 8 -4 15 -2 15 2 0 9 -7 16
-15z m-13 -31 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m106
-101 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-62 -51 c-7 -2
-18 1 -23 6 -8 8 -4 9 13 5 13 -4 18 -8 10 -11z m134 -92 c3 -5 -3 -10 -15
-10 -12 0 -18 5 -15 10 3 6 10 10 15 10 5 0 12 -4 15 -10z m-35 -50 c12 -8 11
-10 -7 -10 -13 0 -23 5 -23 10 0 13 11 13 30 0z m74 -25 c3 -9 18 -15 35 -15
16 0 33 -4 36 -10 3 -5 14 -10 24 -10 10 0 24 -7 31 -15 7 -8 23 -15 36 -15
12 0 26 -4 29 -10 3 -5 17 -10 30 -10 13 0 27 -4 30 -10 3 -5 21 -10 39 -10
19 0 39 -7 46 -15 7 -8 25 -15 40 -15 15 0 33 -7 40 -15 7 -8 25 -15 41 -15
15 0 31 -4 34 -10 3 -5 17 -10 31 -10 13 0 32 -4 42 -9 54 -26 91 -40 122 -46
19 -3 50 -8 68 -12 18 -3 35 -9 38 -14 3 -5 15 -9 28 -9 12 0 31 -7 42 -15 10
-8 27 -15 36 -15 9 0 29 -6 45 -14 15 -8 46 -19 68 -26 50 -15 163 -68 170
-81 4 -5 17 -9 30 -9 13 0 27 -4 30 -10 3 -5 15 -10 26 -10 10 0 27 -4 37 -9
68 -34 135 -61 151 -61 10 0 24 -7 31 -15 7 -8 22 -15 33 -15 11 0 18 -3 14
-6 -13 -13 -76 -5 -117 15 -24 12 -51 21 -61 21 -9 0 -22 7 -29 15 -7 8 -19
15 -26 15 -14 0 -23 4 -81 34 -17 9 -40 16 -51 16 -12 0 -24 5 -27 10 -3 6
-16 10 -28 10 -12 0 -37 9 -55 20 -18 11 -49 20 -70 20 -21 0 -43 5 -49 11
-14 14 -110 49 -134 49 -9 0 -34 8 -56 19 -54 26 -99 41 -168 58 -33 8 -73 21
-88 29 -16 8 -35 14 -43 14 -8 0 -22 4 -32 9 -23 11 -81 32 -109 38 -13 3 -23
9 -23 14 0 5 -13 9 -29 9 -25 0 -71 18 -153 61 -10 5 -24 9 -32 9 -7 0 -19 7
-26 15 -7 8 -25 15 -41 15 -16 0 -29 4 -29 9 0 5 -9 13 -20 16 -11 4 -18 11
-15 16 10 15 52 10 59 -6z m1306 -25 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10
0 6 7 10 15 10 8 0 15 -4 15 -10z m64 -46 c18 -26 18 -26 -1 -16 -11 6 -25 18
-33 26 -10 13 -10 16 1 16 8 0 22 -12 33 -26z m106 -4 c0 -5 -2 -10 -4 -10 -3
0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m54 -26 c9 -4 21 -15 27 -26
10 -18 8 -19 -11 -13 -29 9 -66 45 -46 45 8 0 22 -3 30 -6z m-1399 -24 c3 -5
19 -10 35 -10 15 0 30 -3 32 -7 5 -12 131 -73 149 -73 9 0 22 -7 29 -15 9 -10
9 -15 1 -15 -6 0 -11 5 -11 10 0 6 -13 10 -29 10 -17 0 -33 5 -36 10 -3 6 -17
10 -30 10 -13 0 -27 5 -30 10 -3 6 -20 10 -36 10 -17 0 -32 6 -35 15 -4 8 -15
15 -26 15 -10 0 -16 5 -13 10 3 6 -1 10 -9 10 -8 0 -18 5 -21 10 -3 6 1 10 9
10 8 0 18 -4 21 -10z m1248 -20 c35 -39 17 -39 -25 0 -23 20 -28 30 -17 30 9
0 28 -13 42 -30z m71 21 c4 -5 -3 -7 -14 -4 -23 6 -26 13 -6 13 8 0 17 -4 20
-9z m-1364 -50 c0 -5 12 -11 28 -14 15 -3 36 -13 47 -21 19 -15 19 -15 -7 -16
-16 0 -28 3 -28 8 0 4 -18 15 -40 25 -44 19 -50 27 -20 27 11 0 20 -4 20 -9z
m1197 3 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1227 -50 c0
-2 -11 -4 -25 -4 -14 0 -25 4 -25 9 0 4 11 6 25 3 14 -2 25 -6 25 -8z m72 -34
c17 -15 18 -20 6 -20 -16 0 -78 28 -78 36 0 13 54 1 72 -16z m140 0 l33 -20
-27 0 c-16 0 -28 5 -28 10 0 6 -7 10 -15 10 -8 0 -15 5 -15 10 0 15 16 12 52
-10z m206 11 c42 -23 86 -41 101 -41 9 0 41 -10 71 -21 30 -12 69 -23 85 -26
17 -2 40 -9 53 -14 87 -36 155 -59 174 -59 9 0 18 -3 20 -7 5 -12 76 -43 99
-43 11 0 30 -6 42 -14 12 -8 54 -24 92 -36 82 -26 143 -48 167 -61 10 -5 27
-9 38 -9 11 0 20 -7 20 -16 0 -11 -6 -14 -22 -8 -13 4 -32 8 -43 9 -24 2 -50
11 -88 31 -16 7 -39 14 -52 14 -13 0 -27 5 -30 10 -3 6 -16 10 -28 10 -12 0
-31 7 -41 15 -11 8 -30 15 -42 15 -13 0 -26 5 -29 10 -3 6 -24 10 -46 10 -21
0 -39 3 -39 8 0 11 -190 78 -255 89 -11 2 -24 8 -29 13 -6 6 -19 10 -31 10
-11 0 -29 7 -39 15 -11 8 -30 15 -43 15 -13 0 -23 5 -23 10 0 6 -10 10 -23 10
-13 0 -32 7 -43 15 -10 8 -29 15 -41 15 -13 0 -23 2 -23 4 0 2 -3 11 -6 20 -7
18 25 22 54 7z m-173 -91 c55 -27 106 -50 113 -50 7 0 10 -4 7 -10 -4 -6 6
-10 22 -10 15 0 37 -7 47 -15 11 -8 29 -15 40 -15 23 0 92 -22 102 -33 4 -4
17 -7 28 -7 12 0 53 -12 91 -25 39 -14 78 -25 88 -25 9 0 25 -4 35 -9 24 -13
88 -36 147 -54 93 -29 165 -56 220 -83 29 -15 27 -15 -40 -9 -38 3 -80 13 -92
21 -12 7 -32 14 -43 14 -11 0 -28 4 -38 9 -9 5 -62 21 -117 36 -55 15 -109 31
-120 35 -11 4 -40 15 -65 25 -25 9 -57 23 -72 31 -14 8 -36 14 -47 14 -12 0
-21 3 -21 8 0 8 -46 29 -135 63 -79 29 -120 46 -180 75 -28 13 -55 24 -60 24
-6 0 -21 9 -35 20 l-25 20 25 0 c14 0 71 -22 125 -50z m151 12 c5 -5 29 -14
54 -21 25 -7 58 -19 73 -27 16 -8 39 -14 53 -14 13 0 47 -10 76 -23 29 -13 73
-29 98 -37 25 -7 60 -19 78 -27 19 -8 43 -13 53 -10 11 3 38 -5 62 -17 23 -13
53 -25 67 -28 40 -9 94 -32 108 -45 7 -7 19 -13 27 -13 7 0 26 -7 41 -16 l29
-16 -30 6 c-16 4 -49 9 -73 13 -23 3 -45 10 -48 14 -3 5 -20 9 -39 9 -19 0
-37 5 -40 10 -3 6 -14 10 -23 10 -9 0 -30 7 -46 15 -15 8 -39 15 -51 15 -13 0
-52 12 -87 26 -35 14 -72 30 -83 34 -11 4 -38 15 -60 23 -22 9 -64 24 -92 34
-29 10 -53 21 -53 25 0 5 -6 8 -14 8 -8 0 -27 6 -43 14 -15 8 -44 22 -63 30
-32 14 -33 16 -9 16 14 0 29 -4 35 -8z m-136 -92 c12 -8 11 -10 -7 -10 -13 0
-23 5 -23 10 0 13 11 13 30 0z m60 -30 c0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0
-15 -28 -12 -37 4 -4 8 -17 16 -28 19 -15 4 -14 5 8 6 15 0 27 -3 27 -9z
m1110 -46 c0 -2 -9 -4 -20 -4 -11 0 -20 4 -20 9 0 5 9 7 20 4 11 -3 20 -7 20
-9z m-919 -34 c41 -16 81 -30 89 -30 9 0 20 -4 25 -8 6 -5 37 -15 70 -22 33
-8 71 -20 85 -27 14 -7 37 -13 52 -13 15 0 30 -4 33 -10 3 -5 16 -10 28 -10
13 0 31 -6 42 -14 11 -7 31 -16 45 -19 25 -5 70 -20 120 -40 l25 -9 -23 -9
c-14 -5 -31 -5 -42 1 -10 6 -34 10 -52 10 -19 0 -39 5 -45 11 -10 10 -36 18
-123 39 -53 12 -162 53 -190 72 -14 9 -43 21 -65 28 -22 7 -48 16 -57 21 -10
5 -24 9 -32 9 -18 0 -76 32 -76 43 0 12 15 8 91 -23z m954 20 c3 -5 -1 -10 -9
-10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m-1115 -40 c0 -5 15
-11 33 -11 30 -2 30 -2 5 -6 -15 -2 -38 3 -50 11 -18 13 -19 16 -5 16 9 0 17
-4 17 -10z m1113 -71 c-7 -7 -21 -7 -44 -1 l-34 10 45 1 c31 1 41 -2 33 -10z
m90 -46 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-293 -33 c8
-5 11 -12 8 -16 -10 -9 -38 3 -38 16 0 12 10 13 30 0z m20 -76 l25 -13 -27 -1
c-15 0 -30 5 -33 10 -3 6 -17 10 -31 10 -13 0 -24 4 -24 10 0 12 56 2 90 -16z
m-202 -26 c-7 -19 -35 -21 -35 -3 0 8 9 15 20 15 10 0 17 -5 15 -12z m114 -30
c-13 -13 -54 -7 -59 9 -5 15 27 22 51 10 12 -6 15 -12 8 -19z"/>
<path d="M5805 7273 c-26 -39 -32 -63 -15 -63 18 0 10 -19 -46 -106 -30 -47
-58 -92 -60 -98 -4 -10 64 -66 79 -66 2 0 24 32 48 70 24 39 49 70 56 70 35 0
44 -93 14 -162 l-18 -43 44 -27 43 -27 24 37 c82 129 156 253 156 260 0 8 -71
52 -84 52 -3 0 -12 -13 -21 -30 -17 -32 -35 -40 -35 -15 0 44 -78 142 -139
174 -17 10 -23 6 -46 -26z"/>
<path d="M1933 7087 c-62 -50 -112 -94 -112 -97 0 -3 14 -22 32 -43 l33 -38
42 37 c26 22 47 33 52 28 5 -5 27 -56 49 -114 21 -58 43 -105 48 -105 5 0 24
13 42 28 25 21 32 33 29 52 -4 20 5 32 44 66 37 31 52 39 61 30 17 -17 29 -13
71 23 l38 33 -156 96 c-86 54 -158 97 -159 97 -1 0 -52 -42 -114 -93z m193
-78 c39 -24 40 -26 17 -51 -23 -26 -31 -23 -48 18 -25 61 -21 65 31 33z"/>
<path d="M6136 7045 c-18 -24 -25 -42 -21 -58 5 -18 -9 -42 -65 -112 l-72 -90
37 -32 c21 -18 42 -30 47 -27 15 9 208 253 208 262 0 9 -68 68 -96 85 -7 4
-23 -8 -38 -28z"/>
<path d="M1686 6884 c-50 -52 -104 -112 -121 -132 l-31 -37 37 -33 38 -33 50
52 49 53 86 -82 c47 -45 90 -82 94 -82 5 0 23 16 40 36 l31 36 -84 80 c-47 44
-85 84 -85 89 0 4 8 16 18 26 47 48 48 51 12 88 -19 19 -36 35 -39 34 -4 -1
-46 -43 -95 -95z"/>
<path d="M6250 6861 c-68 -37 -101 -112 -86 -196 8 -39 46 -87 108 -136 l39
-32 29 34 c16 19 29 38 30 42 0 5 -22 29 -49 53 -59 53 -67 73 -47 112 29 55
88 49 150 -17 17 -17 35 -31 41 -31 6 0 26 15 44 34 l32 33 -53 48 c-29 26
-67 54 -85 61 -45 19 -111 17 -153 -5z"/>
<path d="M6521 6694 c-17 -14 -31 -30 -31 -36 0 -6 16 -29 37 -51 l36 -41 -29
-13 c-16 -7 -69 -31 -118 -53 l-90 -40 38 -39 c27 -28 43 -37 61 -34 17 4 32
-5 59 -34 30 -31 35 -43 31 -68 -4 -26 1 -37 30 -65 19 -18 36 -32 38 -29 2 2
30 51 62 109 31 58 70 129 86 157 l29 53 -58 62 c-32 35 -76 82 -97 106 -20
23 -41 42 -45 42 -5 0 -22 -12 -39 -26z m69 -273 c-24 -48 -32 -50 -54 -16
-17 26 -17 26 26 45 23 10 44 17 46 15 2 -2 -6 -21 -18 -44z"/>
<path d="M1393 6567 c-25 -28 -143 -196 -149 -212 -3 -6 13 -24 36 -40 45 -31
45 -31 87 38 10 15 20 27 23 27 3 0 37 -45 76 -100 38 -54 72 -99 75 -100 4 0
19 18 34 40 23 33 26 44 18 63 -9 19 -5 30 25 71 28 38 39 46 57 42 17 -4 28
3 54 39 23 32 29 46 19 50 -7 2 -74 23 -148 45 -74 23 -147 45 -162 50 -21 7
-30 4 -45 -13z m177 -147 c0 -4 -8 -18 -18 -30 l-18 -22 -27 35 c-38 50 -34
58 18 40 25 -8 45 -18 45 -23z"/>
<path d="M6825 6344 c-11 -9 -29 -22 -39 -30 -19 -13 -18 -15 17 -67 20 -29
37 -58 37 -63 0 -6 -42 -39 -92 -74 -51 -35 -94 -64 -96 -66 -5 -4 50 -84 57
-84 4 0 50 29 102 66 l94 65 28 -33 c15 -18 27 -36 27 -40 0 -14 20 -8 60 18
l40 26 -77 112 c-42 61 -88 128 -102 149 -28 42 -29 42 -56 21z"/>
<path d="M1195 6246 c-16 -7 -40 -24 -51 -37 -23 -26 -114 -183 -114 -197 0
-4 20 -18 45 -30 40 -19 45 -20 53 -5 8 13 23 8 110 -41 55 -31 104 -56 109
-56 5 0 28 35 51 79 65 121 57 206 -24 267 -43 33 -130 43 -179 20z m120 -111
c29 -28 31 -45 10 -86 l-16 -30 -59 32 c-33 18 -60 36 -60 41 0 18 59 68 80
68 11 0 32 -11 45 -25z"/>
<path d="M7002 5989 c-22 -15 -31 -27 -27 -38 4 -13 -18 -29 -97 -70 -57 -30
-105 -55 -107 -57 -5 -5 17 -55 34 -77 13 -17 22 -14 162 59 81 42 150 79 152
81 3 2 -8 30 -24 63 -32 66 -43 71 -93 39z"/>
<path d="M3258 5862 c-29 -3 -40 -32 -13 -32 8 0 26 -20 40 -45 54 -91 55
-103 55 -735 0 -543 -1 -590 -19 -653 -11 -39 -32 -85 -49 -106 -17 -21 -28
-41 -25 -45 8 -8 370 -8 399 0 21 6 21 6 -7 37 -16 17 -39 61 -51 97 -20 57
-23 85 -23 220 l0 156 34 42 c42 54 126 110 216 144 73 28 203 52 228 42 22
-8 26 -44 26 -260 0 -109 4 -377 7 -594 6 -346 5 -407 -10 -493 -10 -54 -22
-111 -28 -125 -16 -37 -58 -82 -77 -82 -14 0 -14 -1 -1 -10 20 -13 447 -13
455 0 3 6 0 10 -9 10 -8 0 -28 13 -43 29 -56 55 -62 107 -66 581 l-4 425 -57
175 c-109 334 -126 369 -187 388 -58 19 -58 19 -26 45 15 12 34 33 40 47 11
22 19 25 55 22 46 -3 69 7 172 74 83 53 120 96 166 190 29 60 34 82 34 142 0
174 -104 270 -330 303 -59 9 -839 18 -902 11z m757 -59 c122 -24 189 -63 228
-134 77 -136 -9 -326 -208 -460 -27 -19 -66 -46 -85 -60 -33 -25 -97 -63 -163
-96 -15 -8 -68 -20 -118 -27 -85 -12 -91 -11 -100 6 -6 12 -9 158 -7 394 3
320 5 376 18 384 19 13 366 7 435 -7z"/>
<path d="M6992 5763 c-129 -63 -131 -246 -2 -310 85 -44 179 -17 226 63 22 38
26 54 22 103 -3 39 -11 68 -27 89 -46 65 -147 91 -219 55z m134 -118 c19 -30
11 -81 -16 -95 -70 -38 -139 34 -94 98 22 32 88 31 110 -3z"/>
<path d="M832 5583 c-29 -93 -53 -171 -52 -175 0 -7 65 -28 87 -28 6 0 21 29
33 65 25 75 12 73 159 23 47 -15 88 -28 91 -28 7 0 30 68 30 88 0 6 -49 27
-110 47 -64 21 -110 42 -110 50 0 7 6 30 14 52 l14 40 -40 12 c-22 7 -45 14
-52 17 -7 3 -31 -58 -64 -163z"/>
<path d="M7240 5456 c-23 -8 -36 -20 -38 -34 -3 -17 -22 -26 -105 -52 -56 -18
-106 -36 -111 -40 -8 -8 9 -77 23 -93 4 -4 40 4 81 18 40 14 77 25 82 25 4 0
8 -13 8 -29 0 -38 -39 -84 -95 -111 l-44 -21 15 -41 c8 -23 12 -49 9 -58 -7
-21 32 -215 50 -250 23 -45 87 -63 139 -38 24 10 66 79 66 106 0 16 17 15 23
-2 3 -7 8 -34 12 -58 10 -66 15 -70 64 -59 23 5 45 12 48 15 3 2 -4 49 -16
103 -17 83 -25 102 -51 126 -34 30 -75 35 -118 13 -28 -14 -42 -36 -62 -90
-14 -40 -22 -27 -40 62 -13 66 -19 77 -39 82 -18 4 12 17 115 49 76 24 142 46
145 50 3 3 -1 28 -8 54 -11 36 -18 47 -30 43 -8 -3 -26 -9 -41 -13 -22 -5 -24
-3 -18 13 19 48 25 118 16 162 -21 92 -18 90 -80 68z"/>
<path d="M745 5348 c-9 -39 -65 -321 -65 -328 0 -4 22 -12 50 -17 44 -9 50 -8
55 8 5 14 13 17 28 14 12 -3 66 -14 119 -24 l97 -18 5 21 c20 89 57 289 53
293 -13 12 -91 23 -97 13 -3 -6 -14 -53 -24 -105 -13 -66 -22 -95 -32 -95 -17
0 -17 8 1 90 7 35 12 67 9 71 -2 4 -18 9 -35 11 -36 6 -42 -4 -59 -94 -8 -38
-16 -58 -26 -58 -17 0 -18 -11 6 105 11 53 20 101 20 105 0 5 -15 11 -32 15
-18 4 -41 9 -50 11 -12 4 -19 -2 -23 -18z"/>
<path d="M4215 5032 c-11 -2 -25 -8 -31 -13 -14 -11 -4 -69 19 -119 9 -19 23
-53 32 -75 9 -22 23 -51 32 -65 15 -23 17 -23 24 -5 4 11 8 59 8 107 1 65 5
91 17 102 33 33 160 12 281 -46 71 -35 189 -142 215 -195 15 -32 48 -44 48
-17 0 9 -11 51 -24 93 -14 42 -33 105 -44 141 -27 95 -26 94 -317 95 -132 1
-249 -1 -260 -3z"/>
<path d="M7558 4145 c-4 -22 -7 -67 -7 -100 1 -60 1 -60 10 -20 6 22 8 67 7
100 -3 58 -3 59 -10 20z"/>
<path d="M7528 3880 c-4 -14 -8 -38 -7 -55 0 -30 0 -30 10 -5 6 13 9 38 7 55
-3 29 -3 30 -10 5z"/>
<path d="M7504 3736 c-3 -21 -4 -39 -1 -42 3 -4 9 10 13 30 3 21 4 39 1 42 -3
4 -9 -10 -13 -30z"/>
<path d="M7478 3620 c-4 -14 -8 -36 -7 -50 l0 -25 10 25 c5 13 9 36 7 50 l-3
25 -7 -25z"/>
<path d="M7456 3525 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M7431 3428 c0 -9 -4 -25 -7 -35 -5 -17 -5 -17 6 0 6 10 9 25 7 35 -4
15 -5 15 -6 0z"/>
<path d="M7407 3353 c-4 -3 -7 -16 -6 -27 0 -19 1 -19 10 3 10 24 8 36 -4 24z"/>
<path d="M7355 3189 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M7322 3111 c-17 -48 -14 -63 4 -22 8 19 13 39 11 45 -2 6 -9 -5 -15
-23z"/>
<path d="M7272 2985 c-17 -37 -14 -45 4 -12 9 16 14 31 11 33 -2 2 -9 -7 -15
-21z"/>
<path d="M7167 2765 c-9 -19 -15 -35 -12 -35 7 0 37 61 33 66 -3 2 -12 -12
-21 -31z"/>
<path d="M7120 2679 c-7 -11 -10 -23 -7 -25 2 -2 9 7 15 21 14 30 7 33 -8 4z"/>
<path d="M2693 2513 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M2815 2489 c17 -5 42 -7 56 -3 15 3 44 -2 64 -10 42 -18 110 -22 100
-6 -3 6 -26 10 -51 10 -24 0 -44 5 -44 10 0 6 -32 10 -77 9 -63 -1 -72 -2 -48
-10z"/>
<path d="M3090 2446 c0 -2 11 -6 25 -8 14 -3 25 -1 25 3 0 5 -11 9 -25 9 -14
0 -25 -2 -25 -4z"/>
<path d="M2845 2420 c28 -12 63 -12 70 0 4 6 -12 10 -42 9 -36 0 -43 -3 -28
-9z"/>
<path d="M3155 2420 c3 -5 12 -10 20 -10 8 0 17 5 20 10 4 6 -5 10 -20 10 -15
0 -24 -4 -20 -10z"/>
<path d="M3125 2350 c3 -5 17 -10 31 -10 13 0 24 5 24 10 0 6 -14 10 -31 10
-17 0 -28 -4 -24 -10z"/>
<path d="M2445 2310 c38 -19 85 -27 85 -14 0 17 -39 33 -80 34 l-44 0 39 -20z"/>
<path d="M2170 2180 c0 -5 9 -10 19 -10 11 0 23 5 26 10 4 6 -5 10 -19 10 -14
0 -26 -4 -26 -10z"/>
<path d="M2220 2158 c0 -4 16 -8 35 -8 19 0 35 2 35 4 0 2 -16 6 -35 8 -19 2
-35 0 -35 -4z"/>
<path d="M2920 2080 c0 -5 9 -10 19 -10 11 0 23 -4 26 -10 3 -5 16 -10 28 -10
22 0 22 1 -3 20 -26 21 -70 27 -70 10z"/>
<path d="M3105 2030 c27 -12 43 -12 25 0 -8 5 -22 9 -30 9 -10 0 -8 -3 5 -9z"/>
<path d="M3154 2009 c-3 -5 6 -9 20 -9 31 0 34 6 6 13 -11 3 -23 1 -26 -4z"/>
<path d="M2718 1970 c42 -26 60 -26 37 0 -10 11 -30 20 -44 20 -26 0 -26 0 7
-20z"/>
<path d="M2850 1940 c-27 -4 -31 -7 -17 -13 26 -10 77 -9 77 2 0 14 -21 18
-60 11z"/>
<path d="M2503 1878 c20 -6 37 -14 37 -19 0 -5 14 -9 30 -9 17 0 30 5 30 10 0
6 -13 10 -30 10 -16 0 -30 5 -30 10 0 6 -17 10 -37 9 l-38 -1 38 -10z"/>
<path d="M2750 1880 c19 -5 36 -13 38 -19 5 -14 49 -14 57 -1 3 6 -3 10 -14
10 -11 0 -23 5 -26 10 -3 6 -25 10 -48 9 l-42 -1 35 -8z"/>
<path d="M3080 1865 c13 -16 60 -21 60 -6 0 11 -25 21 -52 21 -17 0 -18 -3 -8
-15z"/>
<path d="M2880 1840 c0 -5 10 -10 23 -10 13 0 32 -7 43 -15 22 -16 84 -20 84
-5 0 6 -8 10 -17 10 -10 0 -36 7 -58 15 -48 17 -75 19 -75 5z"/>
<path d="M2654 1829 c22 -5 45 -14 52 -20 7 -6 18 -8 24 -4 21 13 -24 34 -70
34 l-45 -1 39 -9z"/>
<path d="M2790 1791 c0 -6 11 -11 24 -11 13 0 29 -7 36 -15 7 -8 27 -15 44
-15 32 0 84 -16 123 -38 12 -7 33 -12 46 -12 13 0 39 -7 57 -16 39 -20 101
-35 112 -28 15 9 -64 44 -99 44 -18 0 -33 5 -33 10 0 6 -10 10 -23 10 -13 0
-32 7 -43 15 -10 8 -35 15 -56 15 -20 0 -38 5 -40 11 -6 19 -148 48 -148 30z"/>
<path d="M2985 1180 c-18 -8 -17 -9 8 -9 15 -1 27 4 27 9 0 12 -8 12 -35 0z"/>
<path d="M3085 1140 c41 -21 105 -29 75 -10 -8 5 -25 10 -37 10 -13 0 -23 5
-23 10 0 6 -12 10 -27 10 -26 0 -25 -1 12 -20z"/>
<path d="M3203 1103 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
				</g>
			</svg>
		</Box>
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
